<template>
  <div>
    <search-filter
        :items="customerReceivable"
        title="CustomerReceivable"
        keyTitleForAlphabeticalSort="billNumber"
        :additionalSortOptions="additionalSortOptions"
        :selectedFilters="[]"
        :selectedView="'list'"
        :excludedKeys="['outgoingInvoices']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="true"
        :allowAdd="false"
        @columnClick="addPayment('outgoingInvoice', $event)"
    >
      <!--      result infos-->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT</span>
      </template>

      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-outgoing-invoice
            :outgoing-invoice="item"
            :isPayable="true"
            @click="addPayment('outgoingInvoice', item)"
            @pay="addPayment('outgoingInvoice', item)"
        />
      </template>


      <!--      List view-->

      <!--      Cell bill number-->
      <template #listView_cell_billNumber="{item}">
        <span v-if="item.billNumber != null">{{ item.billNumber }}</span>
        <span v-else>-</span>
      </template>

      <!--      Cell due date-->
      <template #listView_cell__dueDate="{item}">
        <span
            :class="moment(item._dueDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')?'text-warning':(moment(item._dueDate) < moment()?'text-danger':'')">
          {{ item._dueDate|moment('L') }}
        </span>
      </template>


      <!--      Column payment status-->
      <template #listView_head_paymentStatus="{item}">
        <icon icon="cash-register"/>
      </template>
      <template #listView_cell_paymentStatus="{item}">
        <b-badge
            v-if="item._paymentStatus != null"
            pill
            :variant="displayPaymentStatus(item._paymentStatus).variant"
        >
          {{ displayPaymentStatus(item._paymentStatus).value }}
        </b-badge>
      </template>

      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{ item.customerCompany ? item.customerCompany._display : item.customerIndividual._display }}
      </template>

      <!--      Cell sum owed-->
      <template #listView_cell_sumOwed="{item}">
        {{ currency(item._sumOwed) }}
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="$router.push({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: item.id }})"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadOutgoingInvoice(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Payment-->
          <button-payment
              @click.native.stop="addPayment('outgoingInvoice', item)"
              v-b-tooltip.hover.left="capitalize($t('addPayment'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


        </div>
      </template>

    </search-filter>


    <!-- modal payment-->
    <modal-payment
        :payment.sync="currentPayment"
        :sumOwed="sumOwed"
        :title="paymentModalTitle"
        :isOpen.sync="paymentModalShow"
        @submitValidatedPayment="submitValidatedPaymentLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinPayments } from '../../../mixins/mixinPayments'

import mixinCustomerReceivable from './mixinCustomerReceivable'

export default {
  components: {},
  mixins: [mixinCustomerReceivable, mixinPayments],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>