import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { displayPaymentStatus } from '../../../utils/utils'
import { useOutgoingInvoices } from '../../sales/outgoingInvoices/useOutgoingInvoices'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'
import i18n from '../../../libs/i18n'
import SearchFilter from '@/components/searchFilter/SearchFilter'
import CardOutgoingInvoice from '@/components/card/OutgoingInvoice'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonPayment from '@/components/button/Payment'
import moment from 'moment'

export default {
  components: {
    SearchFilter,
    CardOutgoingInvoice,
    ButtonView,
    ButtonDownload,
    ButtonPayment,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'billNumber', label: '#', sortable: true },
      { key: '_dueDate', label: i18n.t('paymentDate'), sortable: true },
      { key: 'paymentStatus', label: i18n.t('paymentStatus'), sortable: false },
      { key: 'contact', label: i18n.t('contact'), sortable: true },
      { key: 'title', label: i18n.t('title'), sortable: false },
      { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
      { key: 'sumOwed', label: i18n.t('remaining'), sortable: true },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const customerReceivable = computed(() => {
      return store.getters['outgoingInvoice/customerReceivable']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchOutgoingInvoices } = useAPI()
    const {
      getOutgoingInvoiceIconStatus,
      getOutgoingInvoiceStatus,
      downloadOutgoingInvoice,
    } = useOutgoingInvoices()

    const getPreTaxAmounts = (outgoingInvoices) => {
      let preTaxAmount = 0
      outgoingInvoices.forEach(oi => preTaxAmount += oi.preTaxAmount)

      return preTaxAmount
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchOutgoingInvoices()

    return {
      // Components
      capitalize,
      currency,
      displayPaymentStatus,
      moment,

      // Data
      additionalSortOptions,
      tableColumns,

      // Computed
      customerReceivable,

      // Methods
      getOutgoingInvoiceIconStatus,
      getOutgoingInvoiceStatus,
      downloadOutgoingInvoice,
      getPreTaxAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}